import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    children: []
  },
  {
    path: "/privacy",
    name: "privacy",
    component: () => import("../components/home/Privacy.vue")
  }, {
    path: "/agreement",
    name: "agreement",
    component: () => import("../components/home/UserAgreement.vue")

  }, {
    path: "/terms",
    name: "terms",
    component: () => import("../views/Terms.vue")

  }
];

const router = new VueRouter({
  routes
});

export default router;
